import { useProfile } from 'api/profile';
import { UserRole } from 'api/users';

export const useCheckUserRole = (...roles: UserRole[]) => {
  const profile = useProfile();

  const profileRoles = profile.data?.data.result?.client?.roles || [];

  const hasRole = roles.some(role => profileRoles.includes(role));

  return hasRole;
};
