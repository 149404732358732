import { UserRole } from 'api/users';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';
import { ReactNode } from 'react';

interface RoleGuardProps {
  readonly roles: UserRole[];
  readonly type: 'allow' | 'block';
  readonly children: ReactNode;
  readonly fallback?: ReactNode;
}

export const RoleGuard = ({ roles, type, fallback, children }: RoleGuardProps) => {
  const hasRole = useCheckUserRole(...roles);

  const isAllowed = type === 'allow' ? hasRole : !hasRole;

  return isAllowed ? children : fallback;
};
